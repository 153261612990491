// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import StroykaSlick from '../shared/StroykaSlick';

// data stubs
import theme from '../../data/theme';

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 379,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function SitePageAboutUs() {
    return (
        <div className="block about-us">
            <Helmet>

                <title>{`BGL lideres de mercado en la venta de bienes de leasing — ${theme.name}`}</title>
                <meta name="description" content={"BGL con más de 28 años de trayectoria en el rubro"} />
                <meta name="keywords" content='Bodegas Generales de leasing, BGL, BGL SA, BGL SPA, Venta de
bienes, liquidadores de bienes, Liquidadores de vehículos, Liquidadores de autos.' />


            </Helmet>

            <div className="about-us__image" style={{ backgroundImage: 'url("images/aboutus.jpg")' }} />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-10">
                        <div className="about-us__body shadow">
                            <h1 className="about-us__title">BGL Comercialización de Activos</h1>
                            <div className="about-us__text typography">
                                <p>
                                    Es una empresa creada en el año 1994 para satisfacer la necesidad de las instituciones 
                                    financieras de vender sus bienes muebles recuperados por contratos de leasing, 
                                    convirtiéndose desde sus inicios en un asesor especializado en comercialización de 
                                    activos mediante procesos rápidos, eficientes y transparentes.
                                </p>
                                <p>
                                    En la actualidad BGL ha diversificado su operación a la comercialización de activos 
                                    por liquidación o renovación de cualquier tipo de empresa con óptimos resultados.
                                </p>
                                <p>
                                  Sus más de 25 años de experiencia, le ha permitido a BGL contar con una fuerza de venta 
                                  altamente especializada, permitiéndole así la comercialización de los más diversos tipos 
                                  de bienes con excelentes resultados.
                                </p>
                                <p>
                                  Gracias a la transparencia, flexibilidad y confiabilidad prestada en sus servicios, 
                                  BGL cuenta hoy con una gran cartera de clientes vendedores y compradores activos, 
                                  para los más variados tipos de bienes (maquinaria para la construcción, minería, 
                                  transporte e industrial, entre otros).
                                </p>
                                <p style={{fontWeight: 'bold'}}>Cuenta con instalaciones de más de 10.000 M2, ubicadas en PAvda. Presidente Eduardo Frei Montalva 8.771, Quilicura.</p>
                            </div>
                            <div className="about-us__team">
                                <h2 className="about-us__team-title">Equipo de venta</h2>
                                <div className="about-us__team-subtitle text-muted">
                                   ¿Necesita ayuda?
                                    <br />
                                    <Link to="/site/contact-us">Contáctenos</Link>
                                    {' '}
                                    y con gusto el equipo de ventas lo ayudará.
                                </div>
                                <div className="about-us__teammates teammates">
                                    <StroykaSlick {...slickSettings}>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img src="images/teammates/teammate-1.jpg" alt="" />
                                            </div>
                                            <div className="teammate__name">Paulo Sánchez</div>
                                            <div className="teammate__position text-muted">Gerente de Ventas</div>
                                            <a href="mailto:psanchez@bgl.cl" className="teammate__email text-muted">psanchez@bgl.cl</a>
                                            <br/>
                                            <a href="tel:+56998280347" className="teammate__position text-muted">(+56-9) 9828 0347</a>
                                        </div>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img src="images/teammates/teammate-2.jpg" alt="" />
                                            </div>
                                            <div className="teammate__name">Jorge Díaz</div>
                                            <div className="teammate__position text-muted">Ejecutivo de Ventas</div>
                                            <a href="mailto:jdiaz@bgl.cl" className="teammate__email text-muted">jdiaz@bgl.cl</a>
                                            <br/>
                                            <a href="tel:+5696293572" className="teammate__position text-muted">(+56-9) 6206 3572</a>
                                        </div>
                                        
                                    </StroykaSlick>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageAboutUs;
