// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import ProductGallery from './ProductGallery';
import { cartAddItem } from '../../store/cart';
import { compareAddItem } from '../../store/compare';
import {  Compare16Svg, Velocity, Gas } from '../../svg';
import { wishlistAddItem } from '../../store/wishlist';
import { quickviewClose } from '../../store/quickview/quickviewActions';

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quantity: 1,
        };
    }

    handleChangeQuantity = (quantity) => {
        this.setState({ quantity });
    };

    render() {
        const {
            product,
            layout,
            quickviewClose,
            wishlistAddItem,
            compareAddItem,
            cartAddItem,
        } = this.props;
        const { quantity } = this.state;
        let prices;

        const Numberformatter = new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 3
        });



        if (product.compareAtPrice) {
            prices = (
                <React.Fragment>
                    <span className="product__new-price"><Currency value={product.price} /></span>
                    {' '}
                    <span className="product__old-price"><Currency value={product.compareAtPrice} /></span>
                </React.Fragment>
            );
        } else {
            prices = (<>
            {product.price } 
            </> );
        }

        return (
            <div className={`product product--layout--${layout}`}>
                <div className="product__content">
                    

                    <div className="product__info">
                        <div className="product__wishlist-compare">
                            <AsyncAction
                                action={() => compareAddItem(product)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Compare"
                                        onClick={run}
                                        className={classNames('btn btn-sm btn-light btn-svg-icon', {
                                            'btn-loading': loading,
                                        })}
                                    >
                                        <Compare16Svg />
                                    </button>
                                )}
                            />
                        </div>
                        <h6>{product.subCategoryName}</h6>
                        <h1 className="product__name">{product.name}</h1>
                        
                        <ul className="product__meta">

                            <li>Categoría: {product.categoryName}</li>
                            <li>Subcategoría: {product.subCategoryName}</li>
                        </ul>
                    </div>
                    <ProductGallery layout={layout} images={product.images} />
                    <div className="product__sidebar">
                        <div className="product__availability">
                            Estado:
                            {' '}
                            <span className="text-success">{product.availability}</span>
                        </div>

                        <div className="product__prices">
                            {prices}
                        </div>
                        <div className="product__description">
                            {product.description}
                        </div>
                        <hr/>
                        <div className="product-card__specs">
                            {product.specs.filter((s =>  !(s.value==="" || s.value===null ))).map((spec, index) => (
                                <div className='' key={index}>
                                {( spec.label==="Kilómetros" || spec.label==="Horas")  &&<><Velocity width={20} height={20}  className='center' /> {
                                    //fomart value to number with . as thousands separator
                                    Numberformatter.format(spec.value)

                                } </> }
                                {( spec.label==="Combustible")  &&<> <Gas width={20} height={20}  className='center' /> {spec.value} </>}
                                </div> 
                            ))}     
                    </div>
                    <hr />



                        <form className="product__options">
                            
                            <div className="form-group product__option">
                                <div className="product__actions">
                                  
                                    <div className="product__actions-item product__actions-item--addtocart">
                                    <Link to={{pathname: "/sitio/contacto/" + product.slug,
                                            state: { product: product }
                                        }}>
                                            <button
                                                type="button"
                                                className={classNames('btn btn-primary btn-lg')}
                                                onClick={quickviewClose}
                                                >
                                                Contactar
                                            </button>
                                      </Link>

                                    </div>
                                    
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
};

Product.defaultProps = {
    layout: 'standard',
};

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    quickviewClose
};

export default connect(
    () => ({}),
    mapDispatchToProps,
)(Product);
