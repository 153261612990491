/* eslint-disable import/no-anonymous-default-export */
export default {
  name: "BGL",
  fullName: "Bodegas Generales Leasing - Líder en Venta de Vehículos y Maquinaria",
  description: "Descubre en Bodegas Generales Leasing una amplia selección de vehículos livianos, camiones, maquinaria de movimiento de tierra y mucho más. Encuentra las mejores ofertas y calidad superior para satisfacer todas tus necesidades de transporte y maquinaria industrial",
  url:
    "https://bgl.cl",
  author: {
    name: "BGL",
    profile_url: "https://bgl.cl",
  },
  contacts: {
    address: "Avda. Presidente Eduardo Frei Montalva 8.771, Quilicura, Región Metropolitana, Chile ",
    email: "bgl@bgl.cl",
    phone: "+56 9 9829 0347",
  },
};
