// react
import React, { useMemo , useReducer, useEffect} from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// application
import shopApi from '../../api/shop';
import { useDeferredData, useProductColumns, useProductTabs } from '../../services/hooks';
import queryString from 'query-string';
// blocks
import BlockBanner from '../blocks/BlockBanner';
import BlockCategories from '../blocks/BlockCategories';
import BlockFeatures from '../blocks/BlockFeatures';
import BlockProducts from '../blocks/BlockProducts';
import BlockProductsCarousel from '../blocks/BlockProductsCarousel';
import BlockSlideShow from '../blocks/BlockSlideShow';
import ReactGA from 'react-ga';

// data stubs
import categories from '../../data/shopBlockCategories';
import theme from '../../data/theme';


function parseQueryOptions(location) {
    const query = queryString.parse(location);
    const optionValues = {};
    if (typeof query.page === 'string') {
        optionValues.page = parseFloat(query.page);
    }
    if (typeof query.limit === 'string') {
        optionValues.limit = parseFloat(query.limit);
    }
    if (typeof query.sort === 'string') {
        optionValues.sort = query.sort;
    }

    if (typeof query.featured === 'string') {
        optionValues.featured = 'true';
    } 
    return optionValues;
}

function parseQueryFilters(location) {
    const query = queryString.parse(location);
    const filterValues = {};

    Object.keys(query).forEach((param) => {
        const mr = param.match(/^filter_([-_A-Za-z0-9]+)$/);

        if (!mr) {
            return;
        }

        const filterSlug = mr[1];

        filterValues[filterSlug] = query[param];
    });

    return filterValues;
}

function parseQuery(location) {
    return [
        parseQueryOptions(location),
        parseQueryFilters(location),
    ];
}

function buildQuery(options, filters) {
    const params = {};

    if (options.page !== 1) {
        params.page = options.page;
    }

    if (options.limit !== 12) {
        params.limit = options.limit;
    }

    if (options.sort !== 'default') {
        params.sort = options.sort;
    }

    if (options.featured == true) {
        params.featured = true
    }

    Object.keys(filters).filter((x) => x !== 'category' && !!filters[x]).forEach((filterSlug) => {
        params[`filter_${filterSlug}`] = filters[filterSlug];
    });

    return queryString.stringify(params, { encode: false });
}

const initialState = {
    init: false,
    /**
     * Indicates that the category is loading.
     */
    categoryIsLoading: true,
    /**
     * Category object.
     */
    category: null,
    /**
     * Indicates that the products list is loading.
     */
    productsListIsLoading: true,
    /**
     * Products list.
     */
    productsList: null,
    /**
     * Products list options.
     *
     * options.page:  number - Current page.
     * options.limit: number - Items per page.
     * options.sort:  string - Sort algorithm.
     */
    options: {
      page: 1,
      limit: 6,
    },
    /**
     * Products list filters.
     *
     * filters[FILTER_SLUG]: string - filter value.
     */
    filters: {},
};

function reducer(state, action) {
    switch (action.type) {
    case 'FETCH_CATEGORY_SUCCESS':
        return {
            ...state,
            init: true,
            categoryIsLoading: false,
            category: action.category,
        };
    case 'FETCH_PRODUCTS_LIST':
        return { ...state, productsListIsLoading: true };
    case 'FETCH_PRODUCTS_LIST_SUCCESS':
        
        return { ...state, productsListIsLoading: false, productsList: action.productsList };
    case 'SET_OPTION_VALUE':
        return {
            ...state,
            options: { ...state.options, page: 1, [action.option]: action.value },
        };
    case 'SET_FILTER_VALUE':
        return {
            ...state,
            options: { ...state.options, page: 1 },
            filters: { ...state.filters, [action.filter]: action.value },
        };
    case 'RESET_FILTERS':
        return { ...state, options: { ...state.options, page: 1 }, filters: {} };
    case 'RESET':
        return state.init ? initialState : state;
    default:
        throw new Error();
    }
}

function init(state) {
    const [options, filters] = parseQuery(window.location.search);

    return { ...state, options, filters };
}



function HomePageOne() {
  const [state, dispatch] = useReducer(reducer, initialState, init);
  
    
    /**
     * Featured products.
     */
    const featuredProducts = useProductTabs(
        useMemo(() => [
            { id: 1, name: <a href={`/bienes?limit=6&featured=true`}>Todos</a>, categorySlug: undefined },
        ], []),
        (tab) => shopApi.getPopularProducts(),
    );
    // Replace current url.
    useEffect(() => {
        const query = buildQuery(state.options, state.filters);
        const location = `${window.location.pathname}${query ? '?' : ''}${query}`;

        window.history.replaceState(null, '', location);
        ReactGA.pageview(window.location.pathname);
    }, [state.options, state.filters]);

    /**
     * Bestsellers.
     */
    useEffect(() => {
        let canceled = false;

        dispatch({ type: 'FETCH_PRODUCTS_LIST' });

        shopApi.getProductsList(
            state.options,
            { ...state.filters},
        ).then((productsList) => {
            if (canceled) {
                return;
            }
            
            dispatch({ type: 'FETCH_PRODUCTS_LIST_SUCCESS', productsList});
        });

        return () => {
            canceled = true;
        };
    }, [dispatch, state.options, state.filters]);

    const bestsellers = (
      <BlockProducts
          title="Bienes liberados"
          layout="large-first"
          featuredProduct={state.productsList ? state.productsList.data[0] : {}}
          product={state.productsList ? state.productsList.data.slice(1, 7) : []}
      />
    );

    return (
        <React.Fragment>
            <Helmet>
                <meta charSet='utf-8' />
                <title>Compra o vende tu Auto/Camión/Maquinaria a través de BGL</title>
                <meta name="description" content={`Compra tu Auto/Camión/Maquinaria de la forma más rápida ytransparente. Si no tienes tiempo para vender tu Auto/Camión/Maquinaria,consigna con nosotros.`} />
                <link rel="canonical" href={`${window.location.href}`} />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="Bodegas Generales de leasing, BGL, venta de autos, autos
usados, autos semi nuevos, venta de camionetas, camionetas usadas, camionetas
semi nuevas, camiones usados, maquinaria a la venta, maquina a la venta,
Bodegas generales de leasing, compraventa, automotora, venta de equipos, Autos
usados baratos, maquinaria usada"/>

            </Helmet>

            {useMemo(() => <BlockSlideShow withDepartments />, [])}

            {useMemo(() => <BlockFeatures />, [])}
            
            {useMemo(() => (
                <BlockProductsCarousel
                    title="Bienes Destacados"
                    layout="grid-4"
                    products={featuredProducts.data.data}
                    loading={featuredProducts.isLoading}
                    groups={featuredProducts.tabs}
                    onGroupClick={featuredProducts.handleTabChange}
                />
            ), [featuredProducts])}
            
            {useMemo(() => <BlockBanner />, [])}
            
            {/*bestsellers*/}

            {useMemo(() => (
                <BlockCategories
                    title="Categorías"
                    layout="classic"
                />
            ), [])}
        </React.Fragment>
    );
}

export default HomePageOne;
