// react
import React, {useState, useEffect} from 'react';

// third-party
import { Link } from 'react-router-dom';

// application
import Menu from './Menu';
import shopApi from '../../api/shop';
import { ArrowRoundedRight6x9Svg } from '../../svg';
import { url } from '../../services/utils';

function useCategories() {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        let canceled = false;
        shopApi.getCategories().then((categories) => {
            
            if (canceled) {
                return;
            }
            setCategories(categories.data);
        });

        return () => {
            canceled = true;
        };
    }, [setCategories]);

    return categories;
}

function DepartmentsLinks(props) {
    const categories = useCategories();
    const linksList = categories.map((category, index) => {
        let arrow = null;
        let submenu = null;
        let itemClass = '';
        
        if (category.children) {
            arrow = <ArrowRoundedRight6x9Svg className="departments__link-arrow" />;
        }

        submenu = (
                <div className="departments__menu">
                    <Menu items={category.children} category={category.name} />
                </div>
            );
        return (
            <li key={index} className={`departments__item ${itemClass}`}>
                <Link to={url.category(category.slug)}>
                    {category.name}
                    {arrow}
                </Link>
                {submenu}
            </li>
        );
    });

    return (
        <ul className="departments__links">
            {linksList}
            <li key={"see-all-button"} className={`departments__item`}>
                <Link to={`/bienes?limit=6`}>
                    VER TODOS
                </Link>
            </li>

        </ul>
    );
}

export default DepartmentsLinks;
