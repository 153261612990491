// react
import React from "react";

function ProductTabSpecification({ product, name, title }) {
  const section = product[name] ?? {};
  const itemsArray = Object.keys(section)
    .map((key) => {
      console.log(key);
      if (key === "otros") {
        return null;
      }
      const item = section[key];
      const value =
        "value" in item ?
        typeof item.value === "string"
          ? item.value
          : [
              item.value.checked !== undefined ? (item.value.checked === "on" ? "SI" : "No") : "",
              item.value.quantity !== undefined ? item.value.quantity : "",
              item.value.obs !== undefined ? item.value.obs : "",
            ]
              .filter(Boolean)
              .join(" ")
            : null;
      if (!value) {
        return null;
      }
      return {
        value,
        label: item.label ?? key,
      };
    })
    .filter((item) => item != null);

  return (
    <div className="spec">
      <h3 className="spec__header">{title}</h3>
      {itemsArray.map((item) => {
        return (
          <div key={item.value} className="spec__row">
            <div className="spec__name">{item.label}</div>
            <div className="spec__value">{item.value}</div>
          </div>
        );
      })}

      {section?.otros && (
        <div className="spec__row">
          <div className="spec__name">Otros</div>
          <div className="spec__value">
            {section?.otros?.value?.checked === "on" ? "SI" : "No"}
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductTabSpecification;
