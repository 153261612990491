/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    title: "INICIO",
    url: "",
  },
  {
    title: "BIENES",
    url: "/bienes?limit=6&page=1",
  },
  { title: "QUIENES SOMOS", url: "/sitio/quienes-somos" },
  { title: "CONTACTO", url: "/sitio/contacto" },
];
