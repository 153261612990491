// react
import React from 'react';

// application
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';
import ToTop from './ToTop';

// data stubs
import theme from '../../data/theme';

export default function Footer() {
    const informationLinks = [
        { title: 'Quienes somos', url: '/sitio/quienes-somos' },
        { title: 'Bienes', url: '/bienes?limit=6&page=1' },
        { title: 'Contacto', url: '/sitio/contacto' },
    ];
    const phoneNumber = '56961848508'; // Reemplaza con tu número de teléfono
    const message = encodeURIComponent('Hola, me gustaría obtener más información')

    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <FooterContacts />
                        </div>
                        <div className="col col-md-3 col-lg-2 col-sm-12">
                            <FooterLinks title="Información" items={informationLinks} />
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-sm-12">
                              &copy; {1900 + new Date().getYear()}, {"Hecho con"}{" "}
                              <i className="fa fa-heart heart" /> {"por "}<a href="https://hbsolutions.cl" target="blank">Higher Bit Solutions</a> 
                              <br />
                              <br />
                              <div className="row">
                                  <div className="col-1">
                                    <a href="https://instagram.com/bgl_cl" target="blank">
                                      <h5><i className="fab fa-instagram"></i></h5>
                                    </a>
                                  </div>
                                  <div className="col-1">
                                    <a href="https://facebook.com/bgl" target="blank">
                                      <h5><i className="fab fa-facebook-f"></i></h5>
                                    </a>
                                  </div>
                                  <div className="col-1">
                                    <a href={`https://wa.me/${phoneNumber}?text=${message}`}  target="blank">
                                      <h5><i className="fab fa-whatsapp"></i></h5>
                                    </a>
                                  </div>
                              </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <ToTop />
        </div>
    );
}
