// react
import React, { Component } from "react";

// third-party
import PropTypes from "prop-types";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { IntlProvider } from "react-intl";
import { ScrollContext } from "react-router-scroll-4";

// application

// pages
import Layout from "./Layout";
import HomePageOne from "./home/HomePageOne";

class Root extends Component {
  componentDidMount() {
    // preloader

    setTimeout(() => {
      const preloader = document.querySelector(".site-preloader");

      preloader?.addEventListener("transitionend", (event) => {
        if (event.propertyName === "opacity") {
          preloader.parentNode.removeChild(preloader);
        }
      });
      preloader?.classList.add("site-preloader__fade");
    }, 500);
  }

  shouldUpdateScroll = (prevRouterProps, { location }) =>
    prevRouterProps && location.pathname !== prevRouterProps.location.pathname;

  render() {
    //const { locale } = this.props;

    return (
      <IntlProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <HelmetProvider>
            <Helmet htmlAttributes={{ dir: "ltr" }} />
            <ScrollContext shouldUpdateScroll={this.shouldUpdateScroll}>
              <Switch>
                <Route
                  path="/"
                  render={(props) => (
                    <Layout
                      {...props}
                      headerLayout="default"
                      homeComponent={HomePageOne}
                    />
                  )}
                />
                <Redirect to="/" />
              </Switch>
            </ScrollContext>
          </HelmetProvider>
        </BrowserRouter>
      </IntlProvider>
    );
  }
}

Root.propTypes = {
  /** current locale */
  locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
  locale: state.locale,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
