export const url = {
    home: () => '/',

    catalog: () => '/bienes/',

    category: (category) => `/bienes/${getSlug(category)}`,

    subCategory: (category, subCategory) => `/bienes/${getSlug(category)}/${getSlug(subCategory)}`,

    product: (product) => `/bienes/${getSlug(product.categoryName)}/${getSlug(product.subCategoryName)}/${getSlug(product.name)}/${product.slug}`,
};

export function getSlug(string) {
    return string
        .toString()
        .toLowerCase()
        .replace(/-/g, '_')
        .replace(/°/g, '_deg_') 
        .replace(/\./g, '_d_')
        .replace(/ /g, '-')
        .replace(/\(/g, '_pl_')
        .replace(/\)/g, '_pr_')
        .replace(/[^\w-]+/g, '');
}

export function getNameBySlug(string) {
   //return inverse function of getSlug
   return string
        .replace(/_deg_/g, '°')
        .replace(/_d_/g, '.')
        .replace(/_pl_/g, '(')
        .replace(/_pr_/g, ')')
        .replace(/-+/g, ' ')
        .replace(/^-/g, '')
        .replace(/-$/g, '')
        .replace(/_/g, '-')
        .toUpperCase();
}

export function getCategoryParents(category) {
    return category.parent ? [...getCategoryParents(category.parent), category.parent] : [];
}
