// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// application
import NavPanel from './NavPanel';
import Search from './Search';



function Header(props) {
    const { layout } = props;
    let bannerSection;
    const phoneNumber = '56961848508'; // Reemplaza con tu número de teléfono
    const message = encodeURIComponent('Hola, me gustaría obtener más información')

    if (layout === 'default') {
        bannerSection = (
            <div className="site-header__middle container">
                <div className="site-header__logo">
                    <Link to="/">
                      <img src="/images/logos/white.png" alt="Logo bgl" width="200"></img>
                    </Link>
                </div>
                <div className="site-header__search">
                    <Search context="header" />
                </div>
                <div className="site-header__phone">
                  <div className="row">
                      <div className="col">
                        <a href="https://instagram.com/bgl_cl" target="blank">
                          <h3><i className="fab fa-instagram"></i></h3>
                        </a>
                      </div>
                      <div className="col">
                        <a href="https://facebook.com/bgl" target="blank">
                          <h3><i className="fab fa-facebook-f"></i></h3>
                        </a>
                      </div>
                      <div className="col">
                        <a href={`https://wa.me/${phoneNumber}?text=${message}`}  target="blank">
                          <h3><i className="fab fa-whatsapp"></i></h3>
                        </a>
                      </div>
                  </div>
                </div>
            </div>
        );
    }

    return (
        <div className="site-header">
            {bannerSection}
            <div className="site-header__nav-panel">
                <NavPanel layout={layout} />
            </div>
        </div>
    );
}

Header.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(['default', 'compact']),
};

Header.defaultProps = {
    layout: 'default',
};

export default Header;
