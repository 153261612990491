import { makeIdGenerator } from "../utils";

const getId = makeIdGenerator();

const categoriesDef = [
  {
    name: "Vehiculos livianos",
    slug: "light-vehicles",
    items: 272,
    children: [
      {
        name: "Autos",
        slug: "autos",
        image: "assets/images/categories/category-1.jpg",
        items: 2,
      },
      {
        name: "Camionetas",
        slug: "camionetas",
        image: "assets/images/categories/category-1.jpg",
        items: 1,
      },
    ],
  },
  {
    name: "Camiones",
    slug: "camiones",
    items: 272,
    children: [
      {
        name: "Camion",
        slug: "camion",
        image: "assets/images/categories/category-1.jpg",
        items: 2,
      },
      {
        name: "Camion Plano",
        slug: "camion-plano",
        image: "assets/images/categories/category-1.jpg",
        items: 1,
      },
    ],
  },
  {
    name: "Otros",
    slug: "otros",
    items: 1,
  },
];

function walkTree(defs, parent = null) {
  let list = [];
  const tree = defs.map((def) => {
    const category = {
      id: getId(),
      name: def.name,
      slug: def.slug,
      image: def.image || null,
      items: def.items || 0,
      customFields: {},
      parent,
      children: [],
    };

    const [childrenTree, childrenList] = walkTree(def.children || [], category);

    category.children = childrenTree;
    list = [...list, category, ...childrenList];

    return category;
  });

  return [tree, list];
}

export function prepareCategory(category, depth) {
  let children;

  if (depth && depth > 0) {
    children = category.children.map((x) => prepareCategory(x, depth - 1));
  }

  return JSON.parse(
    JSON.stringify({
      ...category,
      parent: category.parent ? prepareCategory(category.parent) : null,
      children,
    })
  );
}

export const [categoriesTreeData, categoriesListData] = walkTree(categoriesDef);
