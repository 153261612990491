// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';

export default function BlockBanner() {
    return (
        <div className="block block-banner">
            <div className="container">
                <Link to="/sitio/contacto" className="block-banner__body">
                    <div
                        className="block-banner__image block-banner__image--desktop"
ext                        style={{ backgroundImage: 'url("images/banners/block-banner.png")',backgroundSize: '100% auto', }}
                    />
                    <div
                        className="block-banner__image block-banner__image--mobile"
                        style={{ backgroundImage: 'url("images/banners/block-banner.png")',backgroundSize: '100% auto', }}
                    />
                    <div className="block-banner__title">
                        Te ayudamos vendiendo tu auto
                    </div>
                    <br/>
                    <div className="block-banner__button">    
                        <span className="btn btn-sm btn-primary">Contáctanos</span>
                    </div>
                </Link>
            </div>
        </div>
    );
}
