// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import { cartAddItem } from '../../store/cart';
import {  Velocity, Gas } from '../../svg';
import { compareAddItem } from '../../store/compare';
import { quickviewOpen } from '../../store/quickview/quickviewActions';
import { url } from '../../services/utils';
import { wishlistAddItem } from '../../store/wishlist';

function ProductCard(props) {
    const {
        product,
        layout,
        quickviewOpen,
        cartAddItem,
        compareAddItem,
    } = props;
    const containerClasses = classNames('product-card', {
        'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
        'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
        'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
        'product-card--layout--list': layout === 'list',
        'product-card--layout--horizontal': layout === 'horizontal',
    });

    const Numberformatter = new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 3
    });


    let badges = [];
    let image;
    let price;
    let features;

    let formatter = new Intl.NumberFormat('es', {
          style: 'currency',
          currency: 'CLP',

          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

    if (product.badges.includes('sale')) {
        badges.push(<div key="sale" className="product-card__badge product-card__badge--sale"></div>);
    }
    if (product.badges.includes('hot')) {
        badges.push(<div key="hot" className="product-card__badge product-card__badge--hot">Vendido</div>);
    }
    if (product.badges.includes('new')) {
        badges.push(<div key="new" className="product-card__badge product-card__badge--new">Liberado</div>);
    }

    badges = badges.length ? <div className="product-card__badges-list">{badges}</div> : null;

    if (product.images && product.images.length > 0) {
        image = (
            <div className="product-card__image product-image">
                <Link to={url.product(product)} className="product-image__body">
                    <img className="product-image__img" src={product.images[0]} alt="" />
                </Link>
            </div>
        );
    }

    if (product.compareAtPrice) {
        price = (
            <div className="product-card__prices">
                <span className="product-card__new-price"><Currency value={product.price} /></span>
                {' '}
                <span className="product-card__old-price"><Currency value={product.compareAtPrice} /></span>
            </div>
        );
    } else {
            price = (
                <div>
                <div className="product-card__prices">
                {product.name} 
                </div>
                <div className="product-card__specs">
                    {product.specs.filter((s => (s.label==="Combustible" || s.label==="Kilómetros" || s.label==="Horas") && !(s.value==="" || s.value===null || s.value==="NO"))).map((spec, index) => (
                        <div className='' key={index}>
                          {( spec.label==="Kilómetros" || spec.label==="Horas")  &&<><Velocity width={20} height={20}  className='center' /> {
                            //fomart value to number with . as thousands separator
                            Numberformatter.format(spec.value)
                            

                          }{spec.label==="Kilómetros" &&  "KM"}{spec.label==="Horas" &&  "Hr"} </> }
                          {( spec.label==="Combustible")  &&<> <Gas width={20} height={20}  className='center' /> {spec.value} </>}
                        </div> 
                    ))}     
                </div>
                <div className="product-card__price-cut">
                    <span className="">{product.price}</span>
                </div>
             </div>
             );
        
    }

    if (product.attributes && product.attributes.length) {
        features = (
            <ul className="product-card__features-list">
                {product.attributes.filter((x) => x.featured).map((attribute, index) => (
                    <li key={index}>{`${attribute.name}: ${attribute.values.map((x) => x.name).join(', ')}`}</li>
                ))}
            </ul>
        );
    }
    
    const saveScrollPosition = () => {
        sessionStorage.setItem('scrollPosition',  window.scrollY.toString());
    };

    return (
        <div className={containerClasses}>
            <Link to={url.product(product)} onClick={saveScrollPosition}>
                <AsyncAction
                    action={() => quickviewOpen(product)}
                    render={({ run, loading }) => (
                        <button
                            type="button"
                            onClick={run}
                            className={classNames('product-card__quickview', {
                                'product-card__quickview--preload': loading,
                            })}
                        >
                            Vista preliminar
                        </button>
                    )}
                />
                {badges}
                {image}
                <div className="product-card__info">
                    <div className="product-card__name">
                        <span>{product.subCategoryName}  </span>
                    </div>
                    
                    {features}
                </div>
                <div className="product-card__actions">
                    <div className="product-card__availability">
                        Estado:
                        <span className="text-success">Liberado</span>
                    </div>
                    {price}
                    <div className="product-card__buttons">
                    <div className="col ml-5 mt-1">
                        <Link to={{pathname: "/sitio/contacto/" + product.slug,
                                    state: { product: product }
                                }}>
                        <button
                            type="button"
                            className={classNames('btn btn-primary product-card__addtocart')}
                            >
                            Contactar
                        </button>
                        </Link>
                        

                    </div>
                    </div>
                </div>
            </Link>
        </div>
    );
}

ProductCard.propTypes = {
    /**
     * product object
     */
    product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    quickviewOpen,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductCard);
