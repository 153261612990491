import React from 'react';
import whatsappIcon from '../../svg/whatsapp.svg'; // Reemplaza con la ruta a tu ícono de WhatsApp

const WhatsAppButton = () => {
    const phoneNumber = '56961848508'; // Reemplaza con tu número de teléfono
    const message = encodeURIComponent('Hola, me gustaría obtener más información'); // Mensaje predeterminado

    return (
        <a href={`https://wa.me/${phoneNumber}?text=${message}`} className="whatsapp-float" target="_blank" rel="noopener noreferrer">
            <img src={whatsappIcon} alt="WhatsApp" />
        </a>
    );
};

export default WhatsAppButton;
