// react
import React from 'react';

// data stubs
import theme from '../../data/theme';

export default function FooterContacts() {
    return (
        <div className="site-footer__widget footer-contacts">
            <h5 className="footer-contacts__title">BGL</h5>


            <ul className="footer-contacts__contacts">
                <li>
                    <a className="footer-contacts__icon fas fa-globe-americas" href='https://www.google.com/maps/place/Av.+Pdte.+Eduardo+Frei+Montalva+8771,+8710043+Quilicura,+Regi%C3%B3n+Metropolitana/@-33.3501332,-70.7064621,17z/data=!3m1!4b1!4m6!3m5!1s0x9662c7370ccdddc1:0x88572155126cf2d!8m2!3d-33.3501332!4d-70.7064621!16s%2Fg%2F11hjjqzvvg?entry=ttu'/>
                    <a href='https://www.google.com/maps/place/Av.+Pdte.+Eduardo+Frei+Montalva+8771,+8710043+Quilicura,+Regi%C3%B3n+Metropolitana/@-33.3501332,-70.7064621,17z/data=!3m1!4b1!4m6!3m5!1s0x9662c7370ccdddc1:0x88572155126cf2d!8m2!3d-33.3501332!4d-70.7064621!16s%2Fg%2F11hjjqzvvg?entry=ttu'>Avda. Presidente Eduardo Frei Montalva 8.771, Quilicura, Región Metropolitana, Chile</a>
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-envelope" />
                    bgl@bgl.cl
                </li>
                <li>
                    <i className="footer-contacts__icon fas fa-mobile-alt" />
                    (56-2) 2377 2910
                </li>
                <li>
                    <i className="footer-contacts__icon fas fa-mobile-alt" />
                    +56 9 9828 0347
                </li>
                <li>
                    <i className="footer-contacts__icon fas fa-mobile-alt" />
                    +56 9 6206 3572
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-clock" />
                    Lunes a Viernes 09:00-17:45
                </li>
            </ul>
        </div>
    );
}
