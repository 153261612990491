// react
import React, { useCallback, useState, useEffect } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// application
import Pagination from "../shared/Pagination";
import ProductCard from "../shared/ProductCard";
import {
  Filters16Svg,
  LayoutGrid16x16Svg,
  LayoutGridWithDetails16x16Svg,
  LayoutList16x16Svg,
} from "../../svg";
import { sidebarOpen } from "../../store/sidebar";

function useSetOption(option, filter, dispatch) {
  const callback = useCallback(filter, []);

  return useCallback(
    (data) => {
      dispatch({
        type: "SET_OPTION_VALUE",
        option,
        value: callback(data),
      });
    },
    [option, callback, dispatch]
  );
}

function ProductsView(props) {
  const {
    isLoading,
    productsList,
    options,
    filters,
    dispatch,
    layout: propsLayout,
    grid,
    offcanvas,
    sidebarOpen,
  } = props;
  const [layout] = useState(propsLayout);

  const handlePageChange = useSetOption("page", parseFloat, dispatch);
  const handleSortChange = useSetOption(
    "sort",
    (event) => event.target.value,
    dispatch
  );

  const filtersCount = Object.keys(filters)
    .map((x) => filters[x])
    .filter((x) => x).length;

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("scrollPosition");
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
      sessionStorage.removeItem("scrollPosition"); // Clean up after restoring
    }
  }, []);

  const productsListItems = productsList.data.map((product, index) => (
    <div key={index} className="products-list__item">
      <ProductCard product={product} />
    </div>
  ));

  const rootClasses = classNames("products-view", {
    "products-view--loading": isLoading,
  });

  let content;

  if (productsListItems.length > 0) {
    content = (
      <div className="products-view__content">
        <div className="products-view__options">
          <div
            className={classNames(
              offcanvas === "mobile" &&
                "d-flex justify-content-between align-items-end view-options--offcanvas--mobile ",
              "view-options",
              {
                "view-options--offcanvas--always": offcanvas === "always",
              }
            )}
          >
            <div className="view-options__control ml-0">
              <label htmlFor="view-options-sort">Ordenar por</label>
              <div>
                <select
                  id="view-options-sort"
                  className="form-control form-control-sm"
                  value={options.sort || productsList.sort}
                  onChange={handleSortChange}
                >
                  <option value="name_asc">Nombre (A-Z)</option>
                  <option value="name_desc">Nombre (Z-A)</option>
                  <option value="price_asc">Menor Precio</option>
                  <option value="price_desc">Mayor Precio</option>
                  <option value="last_release">Ultimos Agregados</option>
                </select>
              </div>
            </div>
            <div className="view-options__filters-button ml-auto">
              <button
                type="button"
                className="filters-button"
                onClick={() => sidebarOpen()}
              >
                <Filters16Svg className="filters-button__icon" />
                <span className="filters-button__title">Filtros</span>
                {!!filtersCount && (
                  <span className="filters-button__counter">
                    {filtersCount}
                  </span>
                )}
              </button>
            </div>
            <div className="view-options__legend align-self-center">
              {`${productsList.from} - ${productsList.to} de ${productsList.total}`}
            </div>
          </div>
        </div>

        <div
          className="products-view__list products-list"
          data-layout={layout !== "list" ? grid : layout}
          data-with-features={
            layout === "grid-with-features" ? "true" : "false"
          }
        >
          <div className="products-list__body">{productsListItems}</div>
        </div>

        <div className="products-view__pagination">
          <Pagination
            current={options.page || productsList.page}
            siblings={2}
            total={productsList.pages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    );
  } else {
    content = (
      <div className="products-view__empty">
        <div className="products-view__empty-title">
          No se encontraron bienes
        </div>
        <div className="products-view__empty-subtitle">
          Intenta borrando los filtros
        </div>
        <a
          type="button"
          className="btn btn-primary btn-sm"
          href="bienes/"
          //onClick={handleResetFilters}
        >
          Borrar Filtros
        </a>
      </div>
    );
  }

  return (
    <div className={rootClasses}>
      <div className="products-view__loader" />
      {content}
    </div>
  );
}

ProductsView.propTypes = {
  /**
   * Indicates that products is loading.
   */
  isLoading: PropTypes.bool,
  /**
   * ProductsList object.
   */
  productsList: PropTypes.object,
  /**
   * Products list options.
   */
  options: PropTypes.object,
  /**
   * Products list filters.
   */
  filters: PropTypes.object,
  /**
   * Category page dispatcher.
   */
  dispatch: PropTypes.func,
  /**
   * products list layout (default: 'grid')
   * one of ['grid', 'grid-with-features', 'list']
   */
  layout: PropTypes.oneOf(["grid", "grid-with-features", "list"]),
  /**
   * products list layout (default: 'grid')
   * one of ['grid-3-sidebar', 'grid-4-full', 'grid-5-full']
   */
  grid: PropTypes.oneOf(["grid-3-sidebar", "grid-4-full", "grid-5-full"]),
  /**
   * indicates when sidebar should be off canvas
   */
  offcanvas: PropTypes.oneOf(["always", "mobile"]),
};

ProductsView.defaultProps = {
  layout: "grid",
  grid: "grid-3-sidebar",
  offcanvas: "mobile",
};

const mapDispatchToProps = {
  sidebarOpen,
};

export default connect(() => ({}), mapDispatchToProps)(ProductsView);
