// react
import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

// third-party
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

// application
import PageHeader from '../shared/PageHeader';
import { url } from '../../services/utils';

// data stubs
import theme from '../../data/theme';
import shopApi from '../../api/shop';
import { categoriesListData } from '../../fake-server/database/categories';

function SitePageContactUsAlt(props) {
    const location = useLocation();
    const product = location.state?.product || {};

    const { numerointerno } = props;
    const breadcrumb = [
        { title: 'Inicio', url: '' },
        { title: 'Contacto', url: '' },
    ];
    const wrapRef = React.useRef(null);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState(numerointerno ? numerointerno : '');
    const [message, setMessage] = useState('');
    const [available, setAvailable] = useState(numerointerno ? true : false);
    const [filledForm, setFilledForm] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [link, setLink] = useState('');
    // Helper function to validate email
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    // Helper function to validate phone number (must be at least 9 digits)
    const validatePhone = (phone) => {
        const cleaned = phone.replace(/\D/g, '');
        if (cleaned.length < 9) {
            return false;
        }
        else {
            return true;
        }
    };

    useEffect(() => {
        if (numerointerno !== '' && wrapRef !== null) {
            wrapRef.current.scrollIntoView({ behavior: 'smooth' });
            console.log(product)
            setLink("www.bgl.cl" + url.product(product));
        }
        const checkFormValidity = () => {
            setFilledForm(
                validateEmail(email) &&
                name !== '' &&
                validatePhone(phone) &&
                subject !== '' &&
                message !== ''
            );
        };

        checkFormValidity();
    }, [email, name, phone, subject, message, wrapRef, numerointerno, link]);
    
    


    const handleSendEmail = async (e) => {
        e.preventDefault();
        shopApi.sendContactForm(email, name, phone, subject, message, numerointerno, link);
        setEmail('');
        setName('');
        setPhone('');
        setSubject('');
        setMessage('');
        setLink('');
        setAvailable(false);
        

        alert("Estimad@ su mensaje ha sido enviado con exito!")
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Compra o vende tu próximo vehiculo con nosotros - Contacto — ${theme.name}`}</title>
                <meta name="description" content="Contacta a uno de nuestros profesionales para comprar tu siguiente vehiculo. Puedes contactarnos para vender ese vehiculo que ya no usas" />
                <meta name="keywords" content="Oferta de autos, BGL, Bodegas generales de leasing" />
            </Helmet>

            <PageHeader header="Contáctanos" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="card mb-0 contact-us">
                       
                     
                        <div className="card-body">
                            <div className="contact-us__container">
                                <div className="row">
                                    <div className="col-12 col-lg-6 pb-4 pb-lg-0">
                                        <h4 className="contact-us__header card-title">Nuestra oficina</h4>

                                        <div className="contact-us__address">
                                            <p>
                                            Avda. Presidente Eduardo Frei Montalva 8.771, Quilicura, Región Metropolitana, Chile
                                                <br />
                                                Mail: bgl@bgl.cl
                                                <br />
                                                Teléfono: (+56-2) 2377 2910
                                            </p>

                                            <p>
                                                <strong>Horarios</strong>
                                                <br />
                                                Lunes a Viernes 09:00-14:00 y 14:45-17:45
                                                <br />
                                                Sábados, Domingos y Festivos: Cerrado
                                            </p>
                                        </div>
                                    </div>

                                    <div id="contact"  ref={wrapRef} className="col-12 col-lg-6">
                                        <h4 className="contact-us__header card-title">Contáctenos</h4>

                                        <form onSubmit={handleSendEmail}>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-name">Nombre</label>
                                                    <input type="text" id="form-name" className="form-control" placeholder="Su nombre" value={name} onChange={(e) => setName(e.target.value)}/>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-email">Email</label>
                                                    <input
                                                        type="email"
                                                        id="form-email"
                                                        className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                                        value={email}
                                                        placeholder="Correo electrónico"
                                                        onChange={(e) => {
                                                            setEmail(e.target.value);
                                                            const newEmailError = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value);
                                                            setEmailError(newEmailError);
                                                        }}
                                                    />
                                                    {!validateEmail(email) && <div className="invalid-feedback">El correo no es válido</div>}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="form-phone">Telefono</label>
                                                <input 
                                                    type="text" 
                                                    id="form-phone" 
                                                    className={`form-control ${phoneError ? 'is-invalid' : ''}`} 
                                                    placeholder="Telefono" 
                                                    value={phone} 
                                                    onChange={(e) => {
                                                        const newPhone = e.target.value.replace(/\D/g, '');
                                                        setPhone(newPhone);
                                                        const newPhoneError = !validatePhone(e.target.value);
                                                        setPhoneError(newPhoneError);
                                                    }}
                                                />
                                                {!validatePhone(phone) && (
                                                    <div className="invalid-feedback">El número de teléfono debe tener al menos 9 dígitos</div>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="form-subject">Asunto</label>
                                                <input type="text" id="form-subject" className="form-control" placeholder="Asunto" disabled={available} value={subject} onChange={(e) => setSubject(e.target.value)} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="form-message">Mensaje</label>
                                                <textarea id="form-message" className="form-control" rows="4" value={message} onChange={(e) => setMessage(e.target.value)}/>
                                            </div>
                                            <button type="submit" className="btn btn-primary" disabled={!filledForm}>Enviar</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="contact-us__map">
                        <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26661.001289376483!2d-70.72248292668874!3d-33.35474129989981!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c74f9c81518d%3A0x1656f00dd917648d!2sAv.%20Pdte.%20Eduardo%20Frei%20Montalva%2C%20Quilicura%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses!2scl!4v1674750246713!5m2!1ses!2scl" 

                                frameBorder="0"

                                scrolling="no"
                                marginHeight="0"
                                marginWidth="0"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

SitePageContactUsAlt.propTypes = {
    /** Product slug. */
    numerointerno: PropTypes.string,  
};

SitePageContactUsAlt.defaultProps = {
    numerointerno: '',

};

export default SitePageContactUsAlt;
