import { makeIdGenerator } from "../utils";
import brandsData from "./brands";
import { categoriesListData, prepareCategory } from "./categories";

const getId = makeIdGenerator();

const attributesDef = [
  {
    name: "Color",
    slug: "color",
    values: [
      { name: "White", slug: "white" },
      { name: "Silver", slug: "silver" },
      { name: "Light Gray", slug: "light-gray" },
      { name: "Gray", slug: "gray" },
      { name: "Dark Gray", slug: "dark-gray" },
      { name: "Coal", slug: "coal" },
      { name: "Black", slug: "black" },
      { name: "Red", slug: "red" },
      { name: "Yellow", slug: "yellow" },
      { name: "Pear Green", slug: "pear-green" },
      { name: "Green", slug: "green" },
      { name: "Emerald", slug: "emerald" },
      { name: "Shamrock", slug: "shamrock" },
      { name: "Shakespeare", slug: "shakespeare" },
      { name: "Blue", slug: "blue" },
      { name: "Dark Blue", slug: "dark-blue" },
      { name: "Violet", slug: "violet" },
      { name: "Purple", slug: "purple" },
      { name: "Cerise", slug: "cerise" },
    ],
  },
  {
    name: "Año",
    slug: "year",
    values: [
      { name: "2015" },
      { name: "2016" },
      { name: "2017" },
      { name: "2018" },
    ],
  },
];

const productsDef = [
  {
    slug: "toyota-hilux-2015",
    name: "Toyota Hilux 2015",
    price: 749,
    images: [
      "images/products/product-1.jpg",
      "images/products/product-1-1.jpg",
    ],
    badges: "new",
    rating: 4,
    reviews: 12,
    availability: "in-stock",
    brand: "toyota",
    categories: ["light-vehicles", "camionetas"],
    attributes: [
      { slug: "year", values: "2015" },
      { slug: "color", values: ["red", "black"] },
    ],
  },
  {
    slug: "ford-focus-2015",
    name: "Ford Focus 2015",
    price: 2149,
    images: [
      "images/products/product-1.jpg",
      "images/products/product-1-1.jpg",
    ],
    badges: "",
    rating: 4,
    reviews: 12,
    availability: "in-stock",
    brand: "ford",
    categories: ["light-vehicles", "autos"],
    attributes: [
      { slug: "year", values: "2015" },
      { slug: "color", values: ["gray", "white", "black"] },
    ],
  },
  {
    slug: "ford-ranger-2016",
    name: "Ford Ranger 2016",
    price: 1019,
    images: [
      "images/products/product-2.jpg",
      "images/products/product-2-1.jpg",
    ],
    badges: "hot",
    rating: 5,
    reviews: 3,
    availability: "in-stock",
    brand: "ford",
    categories: ["light-vehicles", "camionetas"],
    attributes: [
      { slug: "year", values: "2015" },
      { slug: "color", values: ["gray", "white"] },
    ],
  },
  {
    slug: "camion-plano-1-2016",
    name: "Camion Plano 1 2016",
    price: 1019,
    images: [
      "images/products/product-2.jpg",
      "images/products/product-2-1.jpg",
    ],
    badges: "",
    rating: 1,
    reviews: 3,
    availability: "por-liberar",
    brand: "ford",
    categories: ["camiones", "camion-plano"],
    attributes: [
      { slug: "year", values: "2016" },
      { slug: "color", values: ["white"] },
    ],
  },
  {
    slug: "camion-1-2016",
    name: "Camion 1 2016",
    price: 1019,
    images: [
      "images/products/product-2.jpg",
      "images/products/product-2-1.jpg",
    ],
    badges: "",
    rating: 1,
    reviews: 3,
    availability: "in-stock",
    brand: "ford",
    categories: ["camiones", "camion"],
    attributes: [
      { slug: "year", values: "2016" },
      { slug: "color", values: ["white"] },
    ],
  },
  {
    slug: "otros-2016-1",
    name: "Otro tipo 1",
    price: 1019,
    images: [
      "images/products/product-2.jpg",
      "images/products/product-2-1.jpg",
    ],
    badges: "hot",
    rating: 2,
    reviews: 3,
    availability: "por-liberar",
    brand: "otros",
    categories: ["otros"],
    attributes: [
      { slug: "year", values: "2015" },
      { slug: "color", values: "white" },
    ],
  },
  {
    slug: "otros-2016-2",
    name: "Otro tipo 2",
    price: 1019,
    images: [
      "images/products/product-2.jpg",
      "images/products/product-2-1.jpg",
    ],
    badges: "",
    rating: 2,
    reviews: 3,
    availability: "por-liberar",
    brand: "otros",
    categories: ["otros"],
    attributes: [
      { slug: "year", values: "2015" },
      { slug: "color", values: "white" },
    ],
  },
  {
    slug: "otros-2016-3",
    name: "Otro tipo 3",
    price: 1019,
    images: [
      "images/products/product-2.jpg",
      "images/products/product-2-1.jpg",
    ],
    badges: "sale",
    rating: 2,
    reviews: 3,
    availability: "in-stock",
    brand: "otros",
    categories: ["otros"],
    attributes: [
      { slug: "year", values: "2015" },
      { slug: "color", values: "white" },
    ],
  },
];

const productsData = productsDef.map((productDef) => {
  let badges = [];

  if (productDef.badges) {
    badges =
      typeof productDef.badges === "string"
        ? [productDef.badges]
        : productDef.badges;
  }

  const categories = categoriesListData
    .filter((category) => productDef.categories.includes(category.slug))
    .map((category) => prepareCategory(category));

  const attributes = (productDef.attributes || [])
    .map((productAttributeDef) => {
      const attributeDef = attributesDef.find(
        (x) => x.slug === productAttributeDef.slug
      );

      if (!attributeDef) {
        return null;
      }

      let valuesDef = [];

      if (typeof productAttributeDef.values === "string") {
        valuesDef = [productAttributeDef.values];
      } else if (productAttributeDef.values) {
        valuesDef = productAttributeDef.values;
      }

      const values = valuesDef
        .map((valueSlug) => {
          const valueDef = attributeDef.values.find(
            (x) => x.slug === valueSlug
          );

          if (!valueDef) {
            return null;
          }

          return {
            ...valueDef,
            customFields: {},
          };
        })
        .filter((x) => x !== null);

      if (!values.length) {
        return null;
      }

      return {
        name: attributeDef.name,
        slug: attributeDef.slug,
        featured: !!productAttributeDef.featured,
        values,
        customFields: {},
      };
    })
    .filter((x) => x !== null);

  return {
    id: getId(),
    name: productDef.name,
    sku: "83690/32",
    slug: productDef.slug,
    price: productDef.price,
    compareAtPrice: productDef.compareAtPrice || null,
    images: productDef.images.slice(),
    badges: badges.slice(),
    rating: productDef.rating,
    reviews: productDef.reviews,
    availability: productDef.availability,
    brand: brandsData.find((x) => x.slug === productDef.brand) || null,
    categories,
    attributes,
    customFields: {},
  };
});

export default productsData;
