// react
import React, {useState, useEffect} from 'react';

// third-party
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// application
import BlockHeader from '../shared/BlockHeader';
import shopApi from '../../api/shop';
import { url } from '../../services/utils';

function useCategories() {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        let canceled = false;
        shopApi.getCategories().then((categories) => {
            
            if (canceled) {
                return;
            }
            setCategories(categories.data);
        });

        return () => {
            canceled = true;
        };
    }, [setCategories]);

    return categories;
}

export default function BlockCategories(props) {
    const categories = useCategories();
    const { title, layout } = props;

    const categoriesList = categories.map((category, index) => {
        const classes = `block-categories__item category-card category-card--layout--${layout}`;

        const subcategories = category.children.slice(0, 5).map((sub, subIndex) => (
            <li key={subIndex}>
                <Link to={url.subCategory(category.slug, sub.slug)}>{sub.name}</Link>
            </li>
        ));

        return (
            <div key={index} className={classes}>
                <div className=" category-card__body">
                    <div className="category-card__image">
                        <Link to={url.category(category.slug)}><h1><i className={category.icon}></i></h1></Link>
                    </div>
                    <div className="category-card__content">
                        <div className="category-card__name">
                            <Link to={url.category(category.slug)}>{category.name}</Link>
                        </div>
                        <ul className="category-card__links">
                            {subcategories}
                        </ul>
                        <div className="category-card__all">
                            <Link to={url.category(category.slug)}>VER MÁS</Link>
                        </div>
                        
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div className={`block block--highlighted block-categories block-categories--layout--${layout}`}>
            <div className="container">
                <BlockHeader title={title} />

                <div className="block-categories__list">
                    {categoriesList}
                </div>
            </div>
        </div>
    );
}

BlockCategories.propTypes = {
    title: PropTypes.string.isRequired,
    categories: PropTypes.array,
    layout: PropTypes.oneOf(['classic', 'compact']),
};

BlockCategories.defaultProps = {
    categories: [],
    layout: 'classic',
};
