import React, { useState } from "react";
import classNames from "classnames";
import ProductTabSpecification from "./ProductTabSpecification"; // Asegúrate de ajustar la ruta de importación
const SECTION_NAMES = {
  head: "Especificaciones",
  bodyinspection: "Inspección",
  accesoriesInventory: "Accesorios e Inventario",
  mecanicState: "Estado Mecánico",
  safetyness: "Seguridad",
};

const ProductTabs = ({ withSidebar, product }) => {
  const [currentTab, setCurrentTab] = useState("head");

  const classes = classNames("product-tabs", {
    "product-tabs--layout--sidebar": withSidebar,
  });

  const tabsBuild = Object.keys(SECTION_NAMES).map((key) => {
    const title = SECTION_NAMES[key];
    if (!product[key] || Object.keys(product[key]).length === 0) {
      return null;
    }
    return {
      key: key,
      title,
      content: (
        <ProductTabSpecification product={product} name={key} title={title} />
      ),
    };
  });

  const tabs = tabsBuild.filter((item) => item != null);

  const tabsButtons = tabs.map((tab) => {
    const buttonClasses = classNames("product-tabs__item", {
      "product-tabs__item--active": currentTab === tab.key,
    });

    return (
      <button
        key={tab.key}
        type="button"
        onClick={() => setCurrentTab(tab.key)}
        className={buttonClasses}
      >
        {tab.title}
      </button>
    );
  });

  const tabsContent = tabs.map((tab) => {
    const contentClasses = classNames("product-tabs__pane", {
      "product-tabs__pane--active": currentTab === tab.key,
    });

    return (
      <div key={tab.key} className={contentClasses}>
        {tab.content}
      </div>
    );
  });

  return (
    <div className={classes}>
      <div className="product-tabs__list">{tabsButtons}</div>
      <div className="product-tabs__content">{tabsContent}</div>
    </div>
  );
};

export default ProductTabs;
