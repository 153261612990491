// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';


export default function BlockFeatures(props) {
    const { layout } = props;

    //const 

    return (
        <div className={`block block-features block-features--layout--${layout}`}>
            <div className="container">
                <div className="block-features__list">
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <h1><i className="fas fa-chalkboard-teacher"></i></h1>
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Asesoría Especializada</div>
                            <div className="block-features__subtitle">Comercialización de activos</div>
                        </div>
                    </div>
                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <h1><i className="far fa-clock"></i></h1>
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Horarios</div>
                            <div className="block-features__subtitle">Lunes a Viernes <br/> 09:00-14:00 <br/> 14:45-17:45 </div>
                        </div>
                    </div>
                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                             <h1><i className="fas fa-list-ol"></i></h1>
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Gran variedad de automotriz</div>
                            <div className="block-features__subtitle">Navega para ver más</div>
                        </div>
                    </div>
                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <h1>
                              <i className="fas fa-hourglass-half"></i>
                            </h1>
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Más de 25 años de expriencia</div>
                            <div className="block-features__subtitle">Comercializando bienes</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

BlockFeatures.propTypes = {
    layout: PropTypes.oneOf(['classic', 'boxed']),
};

BlockFeatures.defaultProps = {
    layout: 'classic',
};
