/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    type: "link",
    label: "Home",
    url: "/",
  },
  {
    type: "link",
    label: "Bienes",
    url: "/bienes",
  },
  {
    type: "link",
    label: "Sobre nosotros",
    url: "/sitio/quienes-somos",
  },
  {
    type: "link",
    label: "Contacto",
    url: "/sitio/contacto",
  },
];
