// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import  currency from 'currency.js';

function Currency(props) {
    const { value, currentCurrency } = props;
    if (value === "Sin Precio") {return <React.Fragment>{`${value}`}</React.Fragment>;}
    return <React.Fragment>{`${currency(value, { separator: '.', decimal: ',' }).format().split(',')[0]}`}</React.Fragment>;
}

Currency.propTypes = {
    /** price value */
    value: PropTypes.number.isRequired,
    /** currency object, specify to override currentCurrency */
    currency: PropTypes.object,
    /** currency object */
    currentCurrency: PropTypes.object,
};

const mapStateToProps = (state) => ({
    currentCurrency: state.currency,
});

export default connect(mapStateToProps)(Currency);
