// react
import React, { useEffect, useState } from "react";

// third-party
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

// application
import PageHeader from "../shared/PageHeader";
import Product from "../shared/Product";
import ProductTabs from "./ProductTabs";
import shopApi from "../../api/shop";
import { url } from "../../services/utils";

// blocks
import BlockLoader from "../blocks/BlockLoader";
import BlockProductsCarousel from "../blocks/BlockProductsCarousel";

// widgets
import WidgetCategories from "../widgets/WidgetCategories";
import WidgetProducts from "../widgets/WidgetProducts";

// data stubs
import categories from "../../data/shopWidgetCategories";
import theme from "../../data/theme";

function ShopPageProduct(props) {
  const { productSlug, layout, sidebarPosition } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [latestProducts, setLatestProducts] = useState([]);

  const breadcrumb = product && [
    { title: "Inicio", url: url.home() },
    { title: "Catalogo", url: url.catalog() },
    { title: product?.categoryName, url: url.category(product?.categoryName) },
    {
      title: product?.subCategoryName,
      url: url.subCategory(product?.categoryName, product?.subCategoryName),
    },
    { title: product.name, url: url.product(product) },
  ];

  // Load product.
  useEffect(() => {
    const controller = new AbortController(); // Crear una instancia de AbortController
    const { signal } = controller;

    setIsLoading(true);

    shopApi
      .getProductBySlug(productSlug, { signal })
      .then((product) => {
        if (!signal.aborted) {
          console.log("SET DATA", product.data);
          setProduct(product.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          console.error("Error fetching product:", error);
        }
        setIsLoading(false);
      });

    return () => {
      controller.abort(); // Abortar la solicitud en la limpieza
    };
  }, [productSlug, setIsLoading]);

  // Load related products.
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    shopApi
      .getRelatedProducts(productSlug, { limit: 8, signal })
      .then((products) => {
        if (!signal.aborted) {
          // Aquí puedes manejar los productos obtenidos, como guardarlos en el estado
          console.log("Productos relacionados:", products);
        }
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          console.error("Error fetching related products:", error);
        }
      });

    return () => {
      controller.abort(); // Cancela la solicitud al desmontarse o cambiar el `productSlug`
    };
  }, [productSlug]);

  // Load latest products.
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    if (layout !== "sidebar") {
      setLatestProducts([]);
    } else {
      shopApi
        .getLatestProducts({ limit: 5, signal })
        .then((result) => {
          if (!signal.aborted) {
            setLatestProducts(result);
          }
        })
        .catch((error) => {
          if (error.name !== "AbortError") {
            console.error("Error fetching latest products:", error);
          }
        });
    }

    return () => {
      controller.abort(); // Cancela la solicitud si cambia el `layout` o el componente se desmonta
    };
  }, [layout]);

  if (isLoading) {
    return <BlockLoader />;
  }

  if (!product) return <div>Producto no encontrado</div>;

  const sidebar = (
    <div className="shop-layout__sidebar">
      <div className="block block-sidebar">
        <div className="block-sidebar__item">
          <WidgetCategories categories={categories} location="shop" />
        </div>
        <div className="block-sidebar__item d-none d-lg-block">
          <WidgetProducts title="Latest Products" products={latestProducts} />
        </div>
      </div>
    </div>
  );
  const content =
    layout === "sidebar" ? (
      <div className="container">
        <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
          {sidebarPosition === "start" && sidebar}
          <div className=" shop-layout__content">
            <div className=" block">
              <Product product={product} layout={layout} />
              <ProductTabs withSidebar product={product} />
            </div>
          </div>
          {sidebarPosition === "end" && sidebar}
        </div>
      </div>
    ) : (
      <React.Fragment>
        <div className="block">
          <div className="container">
            <Product product={product} layout={layout} />
            <ProductTabs product={product} />
          </div>
        </div>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${product.name} - ${product.categoryName}USADOS para la venta - ${theme.name}`}</title>
        <meta
          name="description"
          content={`Se Vende ${product.name} a un valor de ${product.price} para la venta se aceptan ofertas - ${theme.name}`}
        />
        <meta
          property="og:description"
          content={`Se Vende ${product.name} a un valor de ${product.price} para la venta se aceptan ofertas - ${theme.name}`}
        />
        <meta
          property="og:title"
          content={`${product.name} - ${product.categoryName}USADOS para la venta - ${theme.name}`}
        />
        <meta property="og:url" content={`${window.location.href}`} />
        <meta property="og:image" content={`${product.images[0]}`} />
        <meta
          name="keywords"
          content={`${product.name}, ${product.categoryName} ,${theme.name}`}
        />
      </Helmet>
      <PageHeader breadcrumb={breadcrumb} />
      {content}
    </React.Fragment>
  );
}

ShopPageProduct.propTypes = {
  /** Product slug. */
  productSlug: PropTypes.string,
  /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
  layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
  /**
   * sidebar position (default: 'start')
   * one of ['start', 'end']
   * for LTR scripts "start" is "left" and "end" is "right"
   */
  sidebarPosition: PropTypes.oneOf(["start", "end"]),
};

ShopPageProduct.defaultProps = {
  layout: "standard",
  sidebarPosition: "start",
};

export default ShopPageProduct;
